import { useEffect, useState } from "react";
import "./Contact.css";

export const Contact = () => {
  const [name, setName] = useState<string>("");
  const [msg, setMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [validMail, setValidMail] = useState<boolean>(false);

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = new RegExp(emailRegex);
    setValidMail(re.test(email?.toLowerCase() ?? ""));
  }, [email]);

  function handleSignUp() {
    const mail = {
      subject: `Wolke7 Anfrage von ${name}`,
      body: `
        <h3>Nachricht über die Homepage:</h3>

        <table class="tab">
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Name:</td>
            <td style="padding:0px 20px; margin:0px;">${name}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">E-Mail:</td>
            <td style="padding:0px 20px; margin:0px;">${email}</td>
          </tr>
        </table>

        <h3>Persönliche Nachricht:</h3>
        <p>${msg}</p>
        `,
      from: email,
      key: "B51C8DEE-F082-4706-850D-0B976ADBB032",
      confirmationSubject: `Bestätigung Kontaktanfrage bei Wolke7`,
      confirmationBody: `
        <p>Hallo ${name},</p>

        <p>hiermit bestätige ich dir den Empfang deiner Kontaktanfrage bei Wolke7.</p>
        
        <p>Ich melde mich schnellstmöglich bei dir zurück.</p>

        <p>Liebe Grüße</p>
        <p>
        <strong>Sandra Fitz</strong><br/>
        Wedding Planer und freie Traurednerin
        </P>
      `,
    };

    fetch("https://mail.vonohlensoftware.de/mail", {
      method: "Post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(mail),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
          alert("Beim Senden der Nachricht kam es zu einem Fehler");
        } else {
          alert("Anfrage erfolgreich versendet!");
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Beim Senden der Nachricht kam es zu einem Fehler");
      });
  }

  return (
    <div className="contact">
      <div className="container">
        <div className="contact-form">
          <div className="section-title">
            <div className="section-headline">Kontaktieren Sie mich</div>
            <div>
              Bitte füllen Sie die Form aus und wir werden uns so schnell wie
              möglich bei Ihnen melden.
            </div>
          </div>

          <div className="contact-form-input">
            <input
              id="name"
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />

            <input
              id="mail"
              value={email}
              placeholder="E-Mail"
              className={validMail ? "": "invalid-input"}
              onChange={(e) => setEmail(e.target.value)}
            />

            <textarea
              id="msg"
              value={msg}
              placeholder="Nachricht"
              onChange={(e) => setMsg(e.target.value)}
            />

            <button
              id="btn"
              className="msg-btn"
              disabled={!validMail}
              onClick={handleSignUp}
            >
              NACHRICHT SENDEN
            </button>
          </div>
        </div>

        <div className="contact-links">
          <div className="contact-headline">Kontakt Informationen</div>

          <div className="contact-part">
            <div className="contact-part-header">
              <img
                className="icon"
                src="/img/icons/location-dot.svg"
                alt="location-dot"
              />
              <div className="text">Adresse</div>
            </div>

            <div className="contact-part-text">
              <div>
                Herzogin-Elisabeth-Straße 6c
                <br />
                38159 Vechelde
              </div>
            </div>
          </div>

          <div className="contact-part">
            <div className="contact-part-header">
              <img className="icon" src="/img/icons/phone.svg" alt="phone" />
              <div className="text">Tel.</div>
            </div>

            <a className="contact-part-text" href={"tel:+4915750491628"}>
              +49 15750491628
            </a>
          </div>

          <div className="contact-part">
            <div className="contact-part-header">
              <img className="icon" src="/img/icons/email.svg" alt="email" />
              <div className="text">E-Mail</div>
            </div>

            <a
              className="contact-part-text"
              href="mailto:info@wolke7bysandrafitz.de"
              color="inherit"
            >
              info@wolke7bysandrafitz.de
            </a>
          </div>

          <div className="contact-part">
            <a
              className="contact-part-header"
              href="https://www.instagram.com/wolke_7_deinehochzeit"
              color="inherit"
            >
              <img
                className="icon"
                src="/img/icons/instagram.svg"
                alt="instagram"
              />
              <div className="text">Instagram</div>
            </a>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="text-center">
          <div className="section-headline">Impressum</div>
          <p>
            <strong>Sandra Fitz</strong>
            <br />
            Herzogin-Elisabeth-Straße 6c
            <br />
            38159 Vechelde
            <br />
          </p>
          <p>
            Telefon: +49 15750491628
            <br />
            E-Mail: info@wolke7bysandrafitz.de
          </p>
          <p>
            <a href="/dsgvo" rel="nofollow" target="_blank">
              DSGVO
            </a>
          </p>
          <p>
            &copy; Powered by{" "}
            <a href="http://vonohlensoftware.de" rel="nofollow">
              von Ohlen Software Development
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
