import "./App.css";
import { Route, Routes } from "react-router";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Contact } from "./components/Contact";
import { Weddings } from "./components/Weddings";
import { Partner } from "./components/Partner";
import { FAQ, FAQContent } from "./components/FAQ";
import { DSGVO } from "./components/DSGVO";

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hochzeitsplanung" element={<Weddings />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/faqs" element={<FAQ content={FAQContent.BOTH} />} />
        <Route path="/dsgvo" element={<DSGVO />} />
      </Routes>
      <Contact />
    </div>
  );
}

export default App;
