import { useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./Header.css";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [altText, setAltText] = useState(
    "Freie Trauung Braunschweig, Freie Traurednerin"
  );

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setAltText("Freie Trauung Braunschweig, Freie Traurednerin");
        break;
      case "/hochzeitsplanung":
        setAltText("Hochzeitsplanung Braunschweig, Hochzeitsplanerplaner");
        break;
    }
  }, [location.pathname]);

  return (
    <div className="header">
      <div className="intro">
        <h1 id="img-container">
          <a href={`https://wolke7bysandrafitz.de${location.pathname}`}>
            <picture>
              <source
                srcSet="/img/Wolke7_Logo/Wolke7_Logo_512.webp"
                type="image/webp"
                media="(max-width:600px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Wolke7_Logo_1024.webp"
                type="image/webp"
                media="(max-width:900px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Wolke7_Logo_2048.webp"
                type="image/webp"
                media="(min-width:900px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Wolke7_Logo.webp"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <img
                src="/img/Wolke7_Logo/Wolke7_Logo.webp"
                id="img"
                srcSet="image/webp"
                alt={altText}
              />
            </picture>
          </a>
        </h1>
      </div>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="m-auto" onSelect={(k) => k && navigate(k)}>
            <Nav.Link eventKey="/">FREIE TRAUUNG</Nav.Link>
            <Nav.Link eventKey="/hochzeitsplanung">HOCHZEITSPLANUNG</Nav.Link>
            <Nav.Link eventKey="/partner">PARTNER</Nav.Link>
            <Nav.Link eventKey="/faqs">FAQ's</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
