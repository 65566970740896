import "./FAQ.css";

export enum FAQContent {
  FREEWEDDING,
  WEDDINGPLANING,
  BOTH,
}

export interface FAQProps {
  content: FAQContent;
}

const freeWeddingFAQs = [
  {
    question: "Wie lange dauert eine Freie Trauung bei dir?",
    answer:
      "20-45 Minunten maximal. Da ich gelernte Lehrerin bin, weiß ich, dass die Köpfe nach 20 Minuten sowieso ausschalten. In der Kürze liegt die Würze und eine kompakte Freie Trauung ist in meinen Augen angenehmer.",
  },
  {
    question: "Kannst du uns musikalische Untermalung empfehlen?",
    answer:
      "Ja! Durch meine Erfahrung habe ich schon mit vielen Musiker*innen arbeiten dürfen und empfehle diese herzlich gerne weiter.",
  },
  {
    question: "Wie kommst du an unsere Infos zur Freien Trauung?",
    answer:
      "Ab diesem Jahr gibt es ein Interview und einen Fragebogen. Das Interview nehme ich auf und für den Fragebogen habt ihr gewöhnlich 3-6 Monate Zeit. Alles ganz entspannt.",
  },
  {
    question: "Machst du auch Rituale?",
    answer: "Wenn es zum Brautpaar passt, ja!",
  },
  {
    question: "Welche Redensart bevorzugst du?",
    answer:
      "Ich bin eher der Typ ,,locker, humorvoll, romantisch“. Ich sage den Brautpaaren immer, dass ich ihre langjährige beste Freundin bin und so authentisch rüberkommen möchte wie möglich. Der ein oder andere Schmunzler ist natürlich auch vorhanden. Aber ganz wichtig – es wird nichts ins Lächerliche gezogen.",
  },
  {
    question: "Was kostet eine Freie Trauung?",
    answer:
      "Diese hängt zusammen vom Ort der Trauung und einigen anderen Faktoren. Das würde ich dir gerne in einem Kennenlerngespräch in Ruhe erläutern.",
  },
];

const weddingPlanningFAQs = [
  {
    question: "Was beinhaltet deine Planung genau?",
    answer:
      "Alles! Von der Auswahl einer passenden Location für euch bis hin zur Begleitung als Hochzeitsmanager und Tränentrockner an eurem Tag.",
  },
  {
    question:
      "Kann ich einzelne Bereiche bei dir buchen? Wie z.B. nur die Koordination am Hochzeitstag selbst?",
    answer: "Ja!",
  },
  {
    question: "Was kostet eine Hochzeitsplanung?",
    answer:
      "Pauschal schwierig zu beantworten, aber grob 12% des verfügbaren Gesamtbudgets. Eine genaue Aufstellung aller Kosten wird nach unserem ersten Gespräch erstellt.",
  },
];

export const FAQ = (props: FAQProps) => {
  return (
    <div id="faq">
      <div className="container">
        <h2>FAQ's</h2>

        <ol>
          {props.content === FAQContent.FREEWEDDING &&
            freeWeddingFAQs.map((faq) => {
              return (
                <li>
                  <p>{faq.question}</p>
                  <p>{faq.answer}</p>
                </li>
              );
            })}
          {props.content === FAQContent.WEDDINGPLANING &&
            weddingPlanningFAQs.map((faq) => {
              return (
                <li>
                  <p>{faq.question}</p>
                  <p>{faq.answer}</p>
                </li>
              );
            })}
          {props.content === FAQContent.BOTH &&
            [...freeWeddingFAQs, ...weddingPlanningFAQs].map((faq) => {
              return (
                <li>
                  <p>{faq.question}</p>
                  <p>{faq.answer}</p>
                </li>
              );
            })}
        </ol>
      </div>
    </div>
  );
};
