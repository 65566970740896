import { Helmet } from "react-helmet";
import "./Weddings.css";
import { Portfolio } from "./Portfolio";
import { FAQ, FAQContent } from "./FAQ";

export const Weddings = () => {
  return (
    <div id="weddings">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hochzeitsplannering - Sandra Fitz</title>
        <link
          rel="canonical"
          href="https://wolke7bysandrafitz.de/hochzeitsplanung"
        />
        <meta
          name="description"
          content="Als professionel ausgebildete und zertifizierte Hochzeitsplanerin mache ich eure Hochzeit zu einem unvergesslichem Event."
        />
      </Helmet>

      <div id="philosophy">
        <div className="container">
          <h2 id="headline">
            Hochzeits
            <wbr />
            planerin
            <br />
            Meine Philosophie
          </h2>

          <h2 id="image">
            <a href={`https://wolke7bysandrafitz.de/hochzeitsplanung`}>
              <picture>
                <source
                  srcSet="/img/free_wedding_01/free_wedding_01_512.webp"
                  type="image/webp"
                  media="(max-width:600px)"
                />
                <source
                  srcSet="/img/free_wedding_01/free_wedding_01_1024.webp"
                  type="image/webp"
                  media="(max-width:1200px)"
                />
                <img
                  src="/img/free_wedding_01/free_wedding_01.jpg"
                  id="philosophy-img"
                  className="img-responsive"
                  alt="Hochzeitplanung Braunschweig"
                />
              </picture>
            </a>
          </h2>

          <div id="text">
            <p>
              Ihr seid noch ganz am Anfang eurer Planung und wisst nicht wie ihr
              anfangen sollt oder benötigt nur noch beim letzten Feinschliff
              Unterstützung? Ich helfe euch bei euren Fragen und Anliegen.
            </p>
            <p>
              Ihr entscheidet, wie intensiv ich euch durch eure{" "}
              <h3>Hochzeitszeit</h3>{" "}
              begleite. Verliebte Paare schweben sprichwörtlich auf Wolke 7 und
              dieses Gefühl möchte ich mit meinem Unternehmen unterstützen. Mit
              einem Gefühl der Leichtigkeit und Schwerelosigkeit sollt ihr durch
              die Vorbereitungszeit zu eurer Hochzeit gehen.
            </p>
          </div>
        </div>
      </div>

      <div id="about">
        <div className="container">
          <h2 id="image">
            <a href={`https://wolke7bysandrafitz.de/hochzeitsplanung`}>
              <picture>
                <source
                  srcSet="/img/about_50.webp"
                  type="image/webp"
                  media="(max-width:600px)"
                />
                <source
                  srcSet="/img/about_75.webp"
                  type="image/webp"
                  media="(max-width:900px)"
                />
                <source
                  srcSet="/img/about_90.webp"
                  type="image/webp"
                  media="(max-width:1200px)"
                />
                <img
                  src="/img/about.jpg"
                  id="about-img"
                  className="img-responsive"
                  alt="Sandra Fitz – Hochzeitsplanerin"
                />
              </picture>
            </a>
          </h2>

          <h2 id="headline">
            Sandra Fitz
            <br />
            Hochzeits
            <wbr />
            planerin
          </h2>

          <div id="text">
            <p>
              Ich, Sandra, habe als Mädchen schon immer von meiner Hochzeit
              geträumt. Ein weißes Kleid, eine große Feier mit einer perfekten
              Dekoration und eine legendäre Party schwirrten schon lange in
              meinem Kopf. Als ich anfing meine eigene Hochzeit zu planen
              bemerkte ich schnell, wie viel Spaß und Engagement ich an diesem
              Tag investiert habe.
            </p>
            <p>
              Dekorieren gehört schon lange zu meinen größten Leidenschaften. Ob
              zuhause oder für Feiern - es muss was Besonderes sein - und das
              will ich auf meinen Veranstaltungen verkörpern. Es dauerte also
              nicht lange und ich fing an mich zum Thema "
              <h3>Hochzeitsplanung</h3>" zu schulen. 2020 begann ich einen
              Lehrgang an der Laudius Fernuniversität und absolvierte meinen
              Abschluss im Sommer 2021, sodass ich in diesem Jahr auch bereits
              als <h3>Hochzeitsplanerin</h3> meine erste{" "}
              <h3>Hochzeit in Braunschweig</h3> planen durfte.
            </p>
            <p>
              Bei der Planung meiner ersten Hochzeit fragte mich das Brautpaar,
              ob ich mir nicht vorstellen könnte auch ihre Freie Trauung
              durchzuführen und so kam es, dass ich mich ab diesem Zeitpunkt
              auch mit <h3>Freien Trauungen</h3> beschäftigte. Vor allem in{" "}
              <h3>Braunschweig</h3> und Umgebung, aber auch gerne auf der ganzen
              Welt.
            </p>
            <p>
              Wolke 7 ist ein Kindheitstraum, den ich mir nun endlich
              verwirkliche. Egal ob Planung, Freie Trauung oder Dekoration. Ich
              unterstütze euch gerne an diesem besonderen Tag.
            </p>
            <p>Herzlich Willkommen bei Wolke 7, ihr Lieben!</p>
          </div>
        </div>
      </div>

      <Portfolio />
      <FAQ content={FAQContent.WEDDINGPLANING} />
    </div>
  );
};
